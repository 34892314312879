// @flow

import React from 'react';
import { Flex, Text } from '@graphite/uneon';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';

import type {
	TWidget,
	TWidgetDiff,
	TPositionValue,
	TWidgetBox,
	TWidgetBoxBreakpoint,
	TOffsetDevice,
	TMarginDevice,
	TPaddingDevice,
	TGridBreakpointName,
} from '@graphite/types';
import { closestDeviceWithKey } from '@graphite/selectors';

import UnitInput from './UnitInput';
import LockOn from './LockOn';

type TProps = $ReadOnly<{|
	t: string => string,
	data: TWidget,
	unit: number,
	position: TPositionValue,
	currentDevice: TGridBreakpointName,
	save: TWidgetDiff => void,
	currentRef?: {| current: ?HTMLDivElement |},
	typePosition?: 'padding' | 'margin',
|}>;

const columnSx = {
	flexDirection: 'column',
	alignItems: 'center',
};

const rowSx = {
	alignItems: 'center',
	justifyContent: 'space-around',
	width: '100%',
};

const rowBoxSx = {
	width: '90px',
};

const rowBoxLeftSx = {
	...rowBoxSx,
	justifyContent: 'flex-end',
};

const headerSx = {
	textTransform: 'capitalize',
};

const param = {
	title: 'Left',
	key: 'left',
	kind: 'unit',
	info: {
		showUnits: true,
		unitKey: 'leftUnit',
		domain: 'any',
	},
};

const sideValGetters = {
	left: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.left || offsets.centerx : margins.left;
		return `${value || 0}`;
	},
	right: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.right : margins.right;
		return `${value || 0}`;
	},
	top: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.top || offsets.centery : margins.top;
		return `${value || 0}`;
	},
	bottom: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.bottom : margins.bottom;
		return `${value || 0}`;
	},
};

const sideNames = {
	left: 'Left',
	right: 'Right',
	top: 'Top',
	bottom: 'Bottom',
};

const getIsActive = (isAbsolute, offsets, margins, side) => {
	if (isAbsolute) {
		if (side === 'left' && offsets.centerx !== undefined) {
			return true;
		}
		if (side === 'top' && offsets.centery !== undefined) {
			return true;
		}
		return offsets[side] !== undefined;
	}
	return true;
};

function Position({
	data,
	position,
	save,
	unit,
	currentDevice,
	currentRef,
	typePosition = 'margin',
	t,
}: TProps) {
	const isAbsolute = position && position.includes('absolute');
	const sideDomain = isAbsolute ? 'any' : 'nonnegative';

	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		// eslint-disable-next-line
		key: `box-${data._id}`,
	});
	const margins: TMarginDevice | TPaddingDevice = box[typePosition] || emptyObject;
	const offsets: TOffsetDevice = box.offset || emptyObject;

	const sideProps = React.useMemo(
		() =>
			['top', 'right', 'bottom', 'left'].map(side => {
				const isActive = getIsActive(isAbsolute, offsets, margins, side);
				return {
					value: sideValGetters[side](isAbsolute, offsets, margins),
					valueUnit: isActive ? 'px' : 'auto',
					param: _.flow(
						_.set('title', sideNames[side]),
						_.set('key', side),
						_.set('info.domain', sideDomain),
						_.set('info.unitKey', `${side}Unit`),
					)(param),
					change: value => {
						if (isAbsolute) {
							let nextData: TOffsetDevice = offsets;
							if (side === 'left' && nextData.centerx !== undefined) {
								nextData = (_.set(
									'centerx',
									value,
									nextData,
								): TOffsetDevice);
							} else if (side === 'top' && nextData.centery !== undefined) {
								nextData = (_.set(
									'centery',
									value,
									nextData,
								): TOffsetDevice);
							} else {
								nextData = (_.set(side, value, nextData): TOffsetDevice);
							}
							save({
								box: (_.set(
									currentDevice,
									(_.set(
										'offset',
										nextData,
										box,
									): TWidgetBoxBreakpoint),
									data.box,
								): TWidgetBox),
							});
						} else {
							const nextData: TMarginDevice = _.assign(margins, {
								[`${side}`]: value,
							});
							save({
								box: (_.set(
									currentDevice,
									(_.set(
										typePosition,
										nextData,
										box,
									): TWidgetBoxBreakpoint),
									data.box,
								): TWidgetBox),
							});
						}
					},
				};
			}),
		[
			box,
			currentDevice,
			data.box,
			isAbsolute,
			margins,
			offsets,
			save,
			sideDomain,
			typePosition,
		],
	);

	return (
		<>
			<Text variant="bodysm" color="text.primaryalt" sx={headerSx}>
				{t(typePosition)}
			</Text>

			<Flex sx={columnSx}>
				<UnitInput
					t={t}
					value={sideProps[0].value}
					param={sideProps[0].param}
					valueUnit={sideProps[0].valueUnit}
					unit={unit}
					onChange={sideProps[0].change}
				/>
				<Flex sx={rowSx}>
					<Flex sx={rowBoxLeftSx}>
						<UnitInput
							t={t}
							value={sideProps[3].value}
							param={sideProps[3].param}
							valueUnit={sideProps[3].valueUnit}
							unit={unit}
							onChange={sideProps[3].change}
						/>
					</Flex>
					<LockOn
						data={data}
						position={position}
						currentDevice={currentDevice}
						currentRef={currentRef}
						save={save}
						typePosition={typePosition}
					/>
					<Flex sx={rowBoxSx}>
						<UnitInput
							t={t}
							value={sideProps[1].value}
							param={sideProps[1].param}
							valueUnit={sideProps[1].valueUnit}
							unit={unit}
							onChange={sideProps[1].change}
						/>
					</Flex>
				</Flex>
				<UnitInput
					t={t}
					value={sideProps[2].value}
					param={sideProps[2].param}
					valueUnit={sideProps[2].valueUnit}
					unit={unit}
					onChange={sideProps[2].change}
				/>
			</Flex>
		</>
	);
}

export default React.memo<TProps>(Position);
