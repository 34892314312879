// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Text, ButtonGroup } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	t: string => string,
	setActiveTab: string => void,
	activeTab: string,
|}>;

const Title = styled(Text)`
	margin-left: 24px;
	cursor: default;
`;

const Tabs = styled(ButtonGroup)`
	margin: 24px 24px 0;
	display: none;
`;

const Header = ({ t, setActiveTab, activeTab }: TProps) => {
	const tabList = React.useMemo(
		() => [
			{ label: t('Layout'), name: 'layout' },
			{ label: t('Style'), name: 'style' },
		],
		[t],
	);

	const clickBound = React.useCallback(
		(e, name: string) => {
			if (typeof name === 'string') {
				setActiveTab(name);
			}
		},
		[setActiveTab],
	);

	return (
		<>
			<Title variant="title3" color="text.primaryalt">
				{t('Settings')}
			</Title>

			<Tabs
				behavior="radio"
				active={activeTab}
				buttons={tabList}
				shape="rounded"
				variant="normal"
				size="sm"
				colors="accentghost"
				activeColors="accent"
				onClick={clickBound}
			/>
		</>
	);
};

export default React.memo<TProps>(Header);
