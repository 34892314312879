// @flow
import React from 'react';
import type { TWidgetDiff } from '@graphite/types';
import { editWidget } from 'Editor/ducks/widgets';
import WidgetSettings from '@graphite/widget-settings';
import { useTranslation } from 'react-i18next';

import type { TConnectPropsWithControls } from '../constants/types';

const Controls = ({
	gridspec,
	position,
	currentDevice,
	dispatch,
	data,
	instanceId,
	originId,
	currentRef,
}: TConnectPropsWithControls) => {
	const { t } = useTranslation();

	const { _id } = data;
	const [openedPanel, setOpenedPanel] = React.useState(null);
	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, instanceId, originId, dispatch],
	);

	return (
		<WidgetSettings
			t={t}
			openedPanel={openedPanel}
			setOpenedPanel={setOpenedPanel}
			onSave={saveDiff}
			data={data}
			unit={gridspec.unit}
			position={position}
			currentDevice={currentDevice}
			currentRef={currentRef}
		/>
	);
};

export default React.memo<TConnectPropsWithControls>(Controls);
