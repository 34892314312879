// @flow
import React from 'react';
import _ from 'lodash/fp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import useHotkeys from '@graphite/use-hotkeys';
import { Button, PopupWhite, themes, Flex } from '@graphite/uneon';
import type { TId, TEditorLang } from '@graphite/types';
import { useSelector } from '@graphite/use-redux';
import { getCurrentSiteId } from '@graphite/selectors';
import { getLanguage, getGridHighlight } from 'Editor/selectors/editor';
import FrameContext from 'Editor/Frame/Context';
import Design from './Design';
import Pages from './Pages';
import Layers from './Layers';
import ButtonsHistory from './ButtonsHistory';
import ButtonsDevices from './ButtonsDevices';
import ButtonPanel from './ButtonPanel';
import ButtonExtra from './ButtonExtra';
import ButtonPublish from '../ButtonPublish';
import Burger from './Burger';
import Domains from './Domains';

type TProps = $ReadOnly<{|
	goToPreview: () => void,
	panelRef: {| current: ?React$ElementRef<any> |},
	goToDashboard: () => void,
	publishUrl: string,
|}>;

const HEIGHT = 54;
const PANEL_DX = 12;
const PANEL_DY = 12;

const flexLeftSx = {
	flexBasis: '50%',
	alignItems: 'center',
};

const flexRightSx = {
	flexBasis: '50%',
	alignItems: 'center',
	justifyContent: 'flex-end',
};

const WrapPreviewButton = styled.div`
	position: relative;
	margin-left: 21px;
`;

const Builder = ({ goToDashboard, goToPreview, panelRef, publishUrl }: TProps) => {
	const { document: documentFrame, window: windowFrame } = React.useContext(
		FrameContext,
	);

	const language: TEditorLang = useSelector(getLanguage);
	const currentGridHighlight: boolean = useSelector(getGridHighlight);

	const { t } = useTranslation();

	const [currentPanel, setCurrentPanel] = React.useState(null);
	const [isDomainPopup, setDomainPopup] = React.useState(false);
	const [isOpenPublish, setOpenPublishPopup] = React.useState(false);

	const domainRef = React.useRef(null);

	const togglePanel = React.useCallback(
		panel => setCurrentPanel(panel === currentPanel ? null : panel),
		[currentPanel],
	);

	const openDomainPopup = React.useCallback(ref => {
		domainRef.current = ref.current;
		setDomainPopup(true);
	}, []);

	const closeDomainPopup = React.useCallback(() => {
		setDomainPopup(false);
	}, []);

	const closePublishPopup = React.useCallback(() => {
		setOpenPublishPopup(true);
	}, []);

	const fullHeightPanel = ['layers', 'pages'].includes(currentPanel) ? 'full' : 'auto';

	const hidePanel = React.useCallback(() => setCurrentPanel(null), []);

	const panels = React.useMemo(
		() => [
			{ title: t('Design'), value: 'design' },
			{ title: t('Pages'), value: 'pages' },
			{ title: t('Layers'), value: 'layers' },
		],
		[t],
	);

	const siteId: ?TId = useSelector(getCurrentSiteId);
	const getPanel = _.cond([
		[_.isEqual('design'), _.constant(<Design />)],
		[_.isEqual('design-grid'), _.constant(<Design targetTab="grid" />)],
		[_.isEqual('design-colors'), _.constant(<Design targetTab="colors" />)],
		[_.isEqual('design-fonts'), _.constant(<Design targetTab="fonts" />)],
		[_.isEqual('design-effects'), _.constant(<Design targetTab="effects" />)],
		[_.isEqual('pages'), _.constant(siteId ? <Pages siteId={siteId} /> : null)],
		[_.isEqual('layers'), _.constant(<Layers />)],
	]);

	const hotkeyDesignGridHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-grid');
		},
		[togglePanel],
	);

	const hotkeyDesignColorsHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-colors');
		},
		[togglePanel],
	);

	const hotkeyDesignFontsHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-fonts');
		},
		[togglePanel],
	);

	const hotkeyDesignEffectsHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-effects');
		},
		[togglePanel],
	);

	const hotkeyLayersHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('layers');
		},
		[togglePanel],
	);

	const hotkeyPagesHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('pages');
		},
		[togglePanel],
	);

	const hotkeyPreviewHandler = React.useCallback(
		e => {
			e.preventDefault();
			goToPreview();
		},
		[goToPreview],
	);

	// design
	useHotkeys('alt+g', hotkeyDesignGridHandler, documentFrame, windowFrame);
	useHotkeys('alt+c', hotkeyDesignColorsHandler, documentFrame, windowFrame);
	useHotkeys('alt+f', hotkeyDesignFontsHandler, documentFrame, windowFrame);
	useHotkeys('alt+v', hotkeyDesignEffectsHandler, documentFrame, windowFrame);

	useHotkeys('alt+l', hotkeyLayersHandler, documentFrame, windowFrame);
	useHotkeys('alt+p', hotkeyPagesHandler, documentFrame, windowFrame);

	useHotkeys(['Control+p', 'Meta+p'], hotkeyPreviewHandler, documentFrame, windowFrame);

	return (
		<>
			<Flex sx={flexLeftSx} ref={domainRef}>
				<Burger goToDashboard={goToDashboard} openDomainPopup={openDomainPopup} />
				<Flex as="nav">
					{panels.map(panel => (
						<ButtonPanel
							key={panel.value}
							title={panel.title}
							value={panel.value}
							active={currentPanel === panel.value}
							onClick={togglePanel}
						/>
					))}
				</Flex>
			</Flex>
			<ButtonsDevices />
			<Flex sx={flexRightSx}>
				<ButtonsHistory />
				<ButtonExtra
					currentGridHighlight={currentGridHighlight}
					language={language}
				/>
				<WrapPreviewButton>
					<Button variant="primary.rounded.sm" onClick={goToPreview}>
						{t('Preview')}
					</Button>
				</WrapPreviewButton>

				<ButtonPublish
					publishUrl={publishUrl}
					openDomainPopup={openDomainPopup}
					isOpenPublishLink={isOpenPublish}
					setStatePopupLink={setOpenPublishPopup}
				/>
			</Flex>

			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={!!currentPanel}
					anchorEl={panelRef}
					offsetTop={HEIGHT + PANEL_DY}
					offsetLeft={PANEL_DX}
					onClose={hidePanel}
					mutex="topleftcorner"
					height={fullHeightPanel}
					isFixed
				>
					{getPanel(currentPanel)}
				</PopupWhite>
			</ThemeProvider>
			{isDomainPopup && (
				<Domains
					isOpen={isDomainPopup}
					positionRef={domainRef}
					onClose={closeDomainPopup}
					openPublishPopup={closePublishPopup}
				/>
			)}
		</>
	);
};

export default React.memo<TProps>(Builder);
