// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';
import { useTranslation } from 'react-i18next';

import type { TWidgetImage } from '../constants/types';

type TProps = $ReadOnly<{|
	data: TWidgetImage,
	save: (string, string | Object) => void,
|}>;

const Settings = ({ save, data }: TProps) => {
	const { t } = useTranslation();

	/**
		Image
	 */
	const paramListImage = React.useMemo(() => {
		const params = [
			{
				title: t('Link'),
				key: 'src',
				kind: 'string',
				info: { maxLength: 0 },
			},
		];

		if (!data.cropMode || data.cropMode === 'fill' || data.cropMode === 'fit')
			params.push({
				title: t('Position'),
				key: 'objectPosition',
				kind: 'string',
				info: { maxLength: 0 },
			});

		return params;
	}, [t, data.cropMode]);

	const paramSourceImage = React.useMemo(
		() => ({
			src: data.src || '',
			objectPosition: data.objectPosition || '50% 50%',
		}),
		[data.src, data.objectPosition],
	);

	/**
		Adjust
	 */
	const paramListAdjust = React.useMemo(() => {
		const params = [
			{
				title: t('Brightness'),
				key: 'brightness',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Contrast'),
				key: 'contrast',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Saturation'),
				key: 'saturation',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Grayscale'),
				key: 'grayscale',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Hue'),
				key: 'hue',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitDegree',
				},
			},
			{
				title: t('Blur'),
				key: 'blur',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
				},
			},
			{
				title: t('Rotation'),
				key: 'rotation',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitDegree',
				},
			},
		];

		return params;
	}, [t]);

	const paramSourceAdjust = React.useMemo(
		() => ({
			brightness: `${data.brightness || 100}`,
			contrast: `${data.contrast || 100}`,
			saturation: `${data.saturation || 100}`,
			grayscale: `${data.grayscale || 0}`,
			hue: `${data.hue || 0}`,
			blur: `${data.blur || 0}`,
			rotation: `${data.rotation || 0}`,
			unitDegree: '°',
			unitPercent: '%',
		}),
		[
			data.rotation,
			data.blur,
			data.brightness,
			data.contrast,
			data.grayscale,
			data.hue,
			data.saturation,
		],
	);

	/**
		SEO
	 */
	const paramListSeo = React.useMemo(() => {
		const params = [
			{
				title: t('Alt'),
				key: 'alt',
				kind: 'string',
				info: { maxLength: 0, placeholder: 'Description' },
			},
			{
				title: t('Title'),
				key: 'title',
				kind: 'string',
				info: { maxLength: 0, placeholder: 'Title' },
			},
		];

		return params;
	}, [t]);

	const paramSourceSeo = React.useMemo(
		() => ({
			alt: data.alt || '',
			title: data.title || '',
		}),
		[data.alt, data.title],
	);

	return (
		<>
			<Section label={t('Image')}>
				<ListParams
					listName="image"
					paramSource={paramSourceImage}
					paramList={paramListImage}
					unit={1}
					onChange={save}
				/>
			</Section>
			<Section label={t('Adjust')}>
				<ListParams
					listName="adjust"
					paramSource={paramSourceAdjust}
					paramList={paramListAdjust}
					unit={1}
					onChange={save}
				/>
			</Section>
			<Section label={t('SEO')}>
				<ListParams
					listName="seo"
					paramSource={paramSourceSeo}
					paramList={paramListSeo}
					unit={1}
					onChange={save}
				/>
			</Section>
		</>
	);
};

export default React.memo<TProps>(Settings);
