// @flow
import React from 'react';
import { Box, PopupWhite, ToolbarBtn } from '@graphite/uneon';
import type {
	TPositionValue,
	TGridBreakpointName,
	TWidget,
	TWidgetDiff,
} from '@graphite/types';

import Header from './Header';
import Position from './Position';
import Layout from './Layout';

const CONTROL_NAME = 'gear';

const DEFAULT_OPT = {
	noActive: false,
	padding: {
		isShow: true,
	},
	margin: {
		isShow: true,
	},
	layout: {
		isShow: true,
	},
};

type TChildrenProps = $ReadOnly<{|
	activeTab: 'layout' | 'style',
|}>;

type TProps = $ReadOnly<{|
	t: string => string,
	children?: TChildrenProps => React$Node,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	data: TWidget,
	onSave: TWidgetDiff => void,
	unit: number,
	position: TPositionValue,
	currentDevice: TGridBreakpointName,
	currentRef: {| current: ?HTMLDivElement |},
	options?: $ReadOnly<{
		noActive?: boolean,
		padding: $ReadOnly<{
			isShow: boolean,
		}>,
		margin: $ReadOnly<{
			isShow: boolean,
		}>,
		layout: $ReadOnly<{
			isShow: boolean,
		}>,
	}>,
|}>;

const wrapBoxSx = {
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	paddingTop: '24px',
	paddingBottom: '12px',
};

const scrollSx = {
	overflowY: 'overlay',
	overflowX: 'hidden',
	position: 'relative',
	flex: 1,

	'::-webkit-scrollbar': {
		width: '6px',
		backgroundColor: 'transparent',
	},
	'::-webkit-scrollbar-button': {
		display: 'none',
	},
	'::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
		borderRadius: 'md.all',
	},
	'::-webkit-scrollbar-track:hover': {
		backgroundColor: 'bg.primaryaltplus',
	},
	'::-webkit-scrollbar-thumb': {
		backgroundColor: 'transparent',
		borderRadius: 'md.all',
	},
	':hover::-webkit-scrollbar-thumb': {
		backgroundColor: 'bg.secondaryminus',
	},
	'::-webkit-scrollbar-thumb:hover': {
		backgroundColor: 'text.tertiary',
	},
};

const scrollContainerSx = {
	padding: '24px',
};

const WidgetSettings = ({
	t,
	children,
	openedPanel,
	setOpenedPanel,
	data,
	onSave,
	unit,
	position,
	currentDevice,
	currentRef,
	options = DEFAULT_OPT,
}: TProps) => {
	const [activeTab, setActiveTab] = React.useState('layout');
	const anchorRef = React.useRef(document.body);

	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(panel => (panel === CONTROL_NAME ? null : CONTROL_NAME));
		},
		[setOpenedPanel],
	);
	const handleClose = React.useCallback(
		e => {
			e.preventDefault();
			setOpenedPanel(null);
		},
		[setOpenedPanel],
	);
	const isActive = openedPanel === CONTROL_NAME;

	return (
		<>
			{options?.noActive ? (
				<ToolbarBtn
					iconName={CONTROL_NAME}
					variant="sm"
					colors="accent"
					onMouseDown={handleToogleOpen}
					ref={btnEl}
				/>
			) : (
				<ToolbarBtn
					iconName={CONTROL_NAME}
					variant="sm"
					onMouseDown={handleToogleOpen}
					ref={btnEl}
					isActive={isActive}
				/>
			)}

			{isActive && (
				<PopupWhite
					isOpen
					anchorEl={anchorRef}
					offsetTop={54}
					onClose={handleClose}
					mutex="widget-settings"
					width={348}
					isFixed={false}
					height="full"
					placement="left" // когда к body откроется справа)
				>
					<Box sx={wrapBoxSx}>
						<Header t={t} setActiveTab={setActiveTab} activeTab={activeTab} />
						<Box sx={scrollSx}>
							<Box sx={scrollContainerSx}>
								{activeTab === 'layout' && (
									<>
										{options.margin.isShow && (
											<Position
												t={t}
												data={data}
												position={position}
												save={onSave}
												unit={unit}
												currentRef={currentRef}
												currentDevice={currentDevice}
											/>
										)}

										{options.padding.isShow && (
											<Position
												t={t}
												data={data}
												position="grid"
												save={onSave}
												unit={unit}
												currentDevice={currentDevice}
												typePosition="padding"
											/>
										)}

										<Layout
											t={t}
											onChange={onSave}
											data={data}
											unit={unit}
											currentRef={currentRef}
											currentDevice={currentDevice}
											position={position}
											options={options.layout}
										/>

										{children && children({ activeTab })}
									</>
								)}
							</Box>
						</Box>
					</Box>
				</PopupWhite>
			)}
		</>
	);
};

export default React.memo<TProps>(WidgetSettings);
