// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';

import { toggleBlocksTag, getSelectedBlocksTag } from '../libs/draft-utils';

type TProps = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
|}>;

const paramList = [
	{
		title: 'Tag',
		key: 'tag',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'div', label: '<div>' },
					{ name: 'h1', label: '<h1>' },
					{ name: 'h2', label: '<h2>' },
					{ name: 'h3', label: '<h3>' },
					{ name: 'h4', label: '<h4>' },
					{ name: 'h5', label: '<h5>' },
					{ name: 'h6', label: '<h6>' },
				],
			},
		},
	},
];

const Settings = ({ editorState, setEditorState }: TProps) => {
	const paramSource = React.useMemo(
		() => ({
			tag: getSelectedBlocksTag(editorState),
		}),
		[editorState],
	);

	const changeParam = React.useCallback(
		(key, value) => {
			if (key === 'tag' && typeof value === 'string') {
				setEditorState(toggleBlocksTag(editorState)(value));
			}
		},
		[editorState, setEditorState],
	);

	return (
		<Section label="SEO">
			<ListParams
				listName="tag"
				paramSource={paramSource}
				paramList={paramList}
				unit={1}
				onChange={changeParam}
			/>
		</Section>
	);
};

export default React.memo<TProps>(Settings);
