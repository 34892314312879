// @flow
import React from 'react';
import { Params as ListParams } from '@graphite/lists';
import { useTranslation } from 'react-i18next';

import type { TWidgetIcon } from '../constants/types';

type TProps = $ReadOnly<{|
	data: TWidgetIcon,
	save: (string, string | Object) => void,
|}>;

const Settings = ({ save, data }: TProps) => {
	const { t } = useTranslation();

	const paramList = React.useMemo(() => {
		const params = [
			{
				title: t('Name'),
				key: 'iconName',
				kind: 'string',
				info: { maxLength: 140 },
			},
			{
				title: t('Size'),
				key: 'size',
				kind: 'unit',
				info: {
					showUnits: true,
					isRequired: false,
					domain: 'nonnegative',
				},
			},
			{
				title: t('Rotate'),
				key: 'rotation',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'rotationUnit',
				},
			},
			{
				title: t('Scale'),
				key: 'scale',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'scaleUnit',
				},
			},
			{
				title: t('Margin Top'),
				key: 'marginTop',
				kind: 'unit',
				info: {
					showUnits: true,
				},
			},
			{
				title: t('Margin Left'),
				key: 'marginLeft',
				kind: 'unit',
				info: {
					showUnits: true,
				},
			},
		];

		return params;
	}, [t]);

	const paramSource = React.useMemo(
		() => ({
			iconName: data.iconName || '',
			rotation: `${data.rotate || 0}`,
			size: `${data.size || ''}`,
			scale: `${data.scale || 100}`,
			marginTop: `${data.marginTop || 0}`,
			marginLeft: `${data.marginLeft || 0}`,
			rotationUnit: '°',
			scaleUnit: '%',
		}),
		[
			data.iconName,
			data.rotate,
			data.size,
			data.scale,
			data.marginTop,
			data.marginLeft,
		],
	);

	return (
		<ListParams
			listName="icon"
			paramSource={paramSource}
			paramList={paramList}
			unit={1}
			onChange={save}
		/>
	);
};

export default React.memo<TProps>(Settings);
