// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import { Params } from '@graphite/lists';
import type {
	TParams,
	TWidgetBoxBreakpoint,
	TWidget,
	TGridBreakpointName,
	TWidgetDiff,
	TPositionValue,
	TUnit,
	TWidgetBox,
	TListParamsOnClick,
} from '@graphite/types';
import { closestDeviceWithKey } from '@graphite/selectors';
import { getRect } from '@graphite/use-rect';

type TProps = $ReadOnly<{|
	t: string => string,
	data: TWidget,
	unit: number,
	currentDevice: TGridBreakpointName,
	onChange: TWidgetDiff => void,
	currentRef?: {| current: ?HTMLDivElement |},
	position: TPositionValue,
	options?: $ReadOnly<{
		isShow: boolean,
	}>,
|}>;

const DEFAULT_OPT = {
	isShow: true,
};

const listStyle = {
	marginBottom: '12px',
};

const Layout = ({
	t,
	unit,
	currentRef,
	currentDevice,
	onChange,
	data,
	position,
	options = DEFAULT_OPT,
}: TProps) => {
	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		// eslint-disable-next-line
		key: `box-${data._id}`,
	});

	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Width'),
				key: 'width',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'widthUnit',
					units:
						position === 'absolute'
							? ['px', 'auto']
							: ['px', 'unit', '%', 'auto'],
				},
			},
			{
				title: t('Height'),
				key: 'height',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'heightUnit',
					units:
						position === 'absolute'
							? ['px', 'auto']
							: ['px', 'unit', '%', 'auto'],
				},
			},
		],
		[t, position],
	);

	const paramSource = React.useMemo(() => {
		if (position === 'absolute')
			return {
				width: `${box.offset?.width || 'auto'}`,
				widthUnit: `${box.offset?.width ? 'px' : 'auto'}`,
				height: `${box.offset?.height || 'auto'}`,
				heightUnit: `${box.offset?.height ? 'px' : 'auto'}`,
			};

		return {
			width: `${box.width || 'auto'}`,
			widthUnit: box.widthUnit || 'auto',
			height: `${box.height || 'auto'}`,
			heightUnit: box.heightUnit || 'auto',
		};
	}, [position, box]);

	const changeParam = React.useCallback(
		(key, value) => {
			if (position === 'absolute') {
				onChange({
					box: _.set(`${currentDevice}.offset.${key}`, value, data.box),
				});
			} else {
				onChange({ box: _.set(`${currentDevice}.${key}`, value, data.box) });
			}
		},
		[onChange, currentDevice, data.box, position],
	);

	const changeUnitGrid = React.useCallback(
		(key, unitNext) => {
			const unitKey = `${key}Unit`;

			let unitPrev: TUnit = box[unitKey] || 'auto';
			if (unitPrev === unitNext) {
				return;
			}

			// Convert (%/px/unit/auto <-> %/px/unit/auto)
			// по необходимости получаем ректы виджета для % расчетов
			// или когда не хватает данных, что бы получить px

			let calcValue: ?number = box[key];

			if (!calcValue) {
				const rect = getRect(currentRef?.current);
				calcValue = rect[key];
				unitPrev = 'px';
			}

			if (unitNext === 'px') {
				if (unitPrev === 'unit') {
					calcValue *= unit;
				} else {
					const rect = getRect(currentRef?.current);
					calcValue = rect[key];
				}
			}

			if (unitNext === 'unit') {
				if (unitPrev === 'px') {
					calcValue /= unit;
				} else {
					const rect = getRect(currentRef?.current);
					calcValue = rect[key] / unit;
				}
			}

			if (unitNext === '%') {
				if (unitPrev === 'px') {
					const parrentRect = getRect(currentRef?.current?.parentElement);
					calcValue = (calcValue / parrentRect[key]) * 100;
				} else if (unitPrev === 'unit') {
					const parrentRect = getRect(currentRef?.current?.parentElement);
					calcValue = ((calcValue * unit) / parrentRect[key]) * 100;
				} else {
					const parrentRect = getRect(currentRef?.current?.parentElement);
					calcValue = parseInt((calcValue / parrentRect[key]) * 100, 10);
				}
			}

			if (unitNext === 'auto') {
				calcValue = null;
			}

			const newBox: TWidgetBox = _.flow(
				_.set(`${currentDevice}.${key}`, calcValue),
				_.set(`${currentDevice}.${unitKey}`, unitNext),
			)(data.box);

			onChange({ box: newBox });
		},
		[currentDevice, box, currentRef, unit, data.box, onChange],
	);

	const changeUnitAbs = React.useCallback(
		(key, unitNext) => {
			if (key === 'height' || key === 'width') {
				// ToDo по умолчанию тут нет unitKey
				let unitPrev: TUnit = box.offset?.[key] ? 'px' : 'auto';

				if (unitPrev === unitNext) {
					return;
				}

				// Convert (px/auto <-> px/auto)

				let calcValue: ?number = box.offset?.[key];

				if (!calcValue) {
					const rect = getRect(currentRef?.current);
					calcValue = rect[key];
					unitPrev = 'px';
				}

				if (unitNext === 'auto') {
					calcValue = null;
				}

				const newBox: TWidgetBox = _.set(
					`${currentDevice}.offset.${key}`,
					calcValue,
					data.box,
				);

				onChange({ box: newBox });
			}
		},
		[currentDevice, box, currentRef, data.box, onChange],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key, unitNext) => {
			if (position === 'absolute') {
				changeUnitAbs(key, unitNext);
			} else {
				changeUnitGrid(key, unitNext);
			}
		},
		[changeUnitGrid, changeUnitAbs, position],
	);

	if (!options.isShow) return null;

	return (
		<Box sx={listStyle}>
			<Params
				listName={`${currentDevice}-widget-settings`}
				paramSource={paramSource}
				paramList={paramList}
				unit={unit}
				onChange={changeParam}
				onClick={clickUnit}
			/>
		</Box>
	);
};

export default React.memo<TProps>(Layout);
