// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Section, Box } from '@graphite/uneon';
import type {
	TWidgetDiff,
	TWidgetBoxBreakpoint,
	TWidgetBox,
	TParamSource,
	TSelectParam,
	TParams,
	TSwitchParam,
} from '@graphite/types';
import { closestDeviceWithKey } from '@graphite/selectors';
import { Params as ListParams } from '@graphite/lists';

import type { TConnectPropsControlsSetting } from './types';

const spacerSx = {
	height: '18px',
};

const directionParam: TSelectParam = {
	title: 'Direction',
	key: 'flexDirection',
	kind: 'select',
	info: {
		list: {
			items: [
				{ name: 'column', label: 'Column' },
				{ name: 'row', label: 'Row' },
			],
		},
	},
};

const horizontalParam: TSelectParam = {
	title: 'Horizontal Align',
	key: 'justifyContent',
	kind: 'select',
	info: {
		list: {
			items: [
				{ name: 'flex-start', label: 'Start' },
				{ name: 'center', label: 'Center' },
				{ name: 'flex-end', label: 'End' },
				{ name: 'space-between', label: 'Space Between' },
				{ name: 'space-around', label: 'Space Around' },
				{ name: 'space-evenly', label: 'Space Evenly' },
				{ name: 'stretch', label: 'Stretch' },
			],
		},
	},
};

const verticalParam: TSelectParam = {
	title: 'Vertical Align',
	key: 'alignItems',
	kind: 'select',
	info: {
		list: {
			items: [
				{ name: 'flex-start', label: 'Start' },
				{ name: 'center', label: 'Center' },
				{ name: 'flex-end', label: 'End' },
				{ name: 'baseline', label: 'Baseline' },
				{ name: 'stretch', label: 'Stretch' },
			],
		},
	},
};

const wrapParam: TSwitchParam = {
	title: 'Wrap',
	key: 'flexWrap',
	kind: 'switch',
	info: emptyObject,
};

const Settings = ({ onSave, currentDevice, data }: TConnectPropsControlsSetting) => {
	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		key: `box-${data._id}`,
	});

	/**
		Flex
	*/
	const paramSourceFlex: TParamSource = React.useMemo(() => {
		return {
			flexDirection: `${box.flexDirection || 'column'}`,
			alignItems: `${box.alignItems || 'stretch'}`,
			justifyContent: `${box.justifyContent || 'center'}`,
			flexWrap: box.flexWrap === 'wrap' ? 'true' : '',
		};
	}, [box.flexDirection, box.alignItems, box.justifyContent, box.flexWrap]);

	const paramListFlex: TParams = React.useMemo(() => {
		if (box.flexDirection === 'row') {
			return [directionParam, horizontalParam, verticalParam, wrapParam];
		}
		const newParam: TSelectParam = {
			..._.set(
				'info.list.items',
				_.get('info.list.items', horizontalParam).filter(
					item => item.name !== 'stretch',
				),
				horizontalParam,
			),
			kind: 'select',
			title: verticalParam.title,
		};
		return [directionParam, newParam];
	}, [box.flexDirection]);

	const saveFlex = React.useCallback(
		(key, value) => {
			if (typeof value !== 'string' && typeof value !== 'boolean') {
				return;
			}

			const finalValue = key === 'flexWrap' ? (value && 'wrap') || 'nowrap' : value;
			let diff: TWidgetDiff = {
				box: (_.set(
					currentDevice,
					(_.set(key, finalValue, box): TWidgetBoxBreakpoint),
					data.box || emptyObject,
				): TWidgetBox),
			};

			if (key === 'flexDirection') {
				if (value === 'column' && box.alignItems !== 'stretch') {
					diff = (_.set(
						`box.${currentDevice}.alignItems`,
						'stretch',
						diff,
					): TWidgetDiff);
				} else if (value === 'row' && box.alignItems !== 'flex-start') {
					diff = (_.set(
						`box.${currentDevice}.alignItems`,
						'flex-start',
						diff,
					): TWidgetDiff);
				}
			}

			onSave(diff);
		},
		[box, currentDevice, data.box, onSave],
	);

	return (
		<Section label="Flow">
			<ListParams
				listName="flex"
				paramSource={paramSourceFlex}
				paramList={paramListFlex}
				unit={1}
				onChange={saveFlex}
			/>
			<Box sx={spacerSx} />
		</Section>
	);
};

export default React.memo<TConnectPropsControlsSetting>(Settings);
